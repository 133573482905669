import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "edit-language-options-for-your-app"
    }}>{`Edit Language Options for Your App`}</h1>
    <hr></hr>
    <p>{`To edit languages to be used within your app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the mobile app or template where you want to edit the available translation options. `}</li>
      <li parentName="ol">{`Select 'Edit Languages' near the bottom of the page. This will direct you to the Edit Languages page.`}</li>
      <li parentName="ol">{`Enable the languages you would like displayed as options within your app. `}</li>
      <li parentName="ol">{`Set the default language you want your app to display in when the patron first opens it. This list will consist of languages that you have enabled. `}</li>
      <li parentName="ol">{`Find the text string code you want to translate and modify the text of the string. Any modified text is displayed in black font. To restore the default text, delete the modified text.`}
        <ul parentName="li">
          <li parentName="ul">{`There are currenlty over 1,000 language elements available for you to customize within your app. The best way to find the exact one you wish to update is to reference the text within your app and search for it on this page. To search the full page, hit Command+F on Mac or Control+F on a Windows. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Click 'Save' at the bottom of the screen. `}</li>
      <li parentName="ol">{`When directed back to the Mobile App screen, click 'Save' at the bottom to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      